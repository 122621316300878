<template>
  <div class="buy-page" v-if="!isSubmitted" style="margin:20px;">

    <div style="text-align: center; margin-bottom: 20px;">
      <h1 style="font-size: 28px; font-weight: bold; color: #333;">{{ $t('message.buyUSDT') }}</h1>
      <p style="font-size: 18px; color: #666;">{{ $t('message.hkdToUSDT') }}</p>
    </div>

    <el-form :model="form" label-width="100px">
      <el-card class="box-card" style="margin-bottom: 20px;">
        <template #header>
          <div class="card-header" style="text-align: left;">
            <span>{{ $t('message.amountInput') }}</span>
          </div>
        </template>
        <!-- 增加USDT输入框 -->
        <el-form-item :label="$t('message.usdtAmount')">
          <el-input v-model="usdtInput" :placeholder="$t('message.enterUSDT')">
            <template #append>
              <div style="width: 50px; text-align: center;">USDT</div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('message.amount')">
          <el-input v-model="hkdInput" :placeholder="$t('message.amount')">
            <template #append>
              <div style="width: 50px; text-align: center;">HKD</div>
            </template>
          </el-input>
          
          <div v-if="(!allowAdjust && parseFloat(hkdInput) % 100 !== 0) || parseFloat(form.amount) < minAmount" class="error-message">
            {{ $t("message.amountHint", { minAmount: minAmount }) }}
          </div>

          <!-- 增加取整功能按钮 -->
          <el-button type="success" @click="adjustHKD" :style="{
              display: 'block', 
              marginTop: (parseFloat(form.amount) % 100 !== 0 || parseFloat(form.amount) < minAmount) ? '0' : '10px'
            }">
            {{ t("message.adjustAmount") }}
          </el-button>

          <!-- 人民币金额按钮 -->
          <el-button v-if="allowAdjust" type="primary" @click="openRmbDialog">
            {{ t("message.rmbAmount") }}
          </el-button>
        </el-form-item>

      <el-form-item :label="$t('message.hkdFee')">
        <el-input v-model="form.hkdFee" disabled>
          <template #append>
            <div style="width: 50px; text-align: center;">HKD</div>
          </template>
        </el-input>
        <div v-if="parseFloat(form.amount) < minAmountForNoFee" class="fee-message">
          {{ $t('message.feeApplied', { amount: minAmountForNoFee, fee: hkdFeeAmount }) }}
        </div>
      </el-form-item>
      </el-card>

      <el-form-item :label="t('message.amount')">
        <div class="usdt-amount">{{ formatNumberWithCommasNoDecimals(form.amount) }} <img src="@/assets/hkd.png" alt="HKD" style="width: 30px; height: 30px; margin-left: 5px;" /></div>
      </el-form-item>


      <el-form-item :label="$t('message.exchangeRate')">
        <div style="display: flex; align-items: center;">
          <el-button v-if="allowAdjust" @click="decreaseExchangeRate">-</el-button>
          <el-input v-model="form.exchangeRate" disabled style="width: 100px; text-align: center;"></el-input>
          <el-button v-if="allowAdjust" @click="increaseExchangeRate">+</el-button>
        </div>
      </el-form-item>

      <el-form-item :label="t('message.usdtAmount')">
        <div class="usdt-amount">{{ formatNumberWithCommas(form.usdtAmount) }} <img src="@/assets/usdt_logo.svg" alt="USDT" style="width: 30px; height: 30px; margin-left: 5px;" /></div>
      </el-form-item>

      <el-form-item :label="$t('message.chain')">
        <el-select v-model="form.chain" placeholder="Select Chain" >
          <el-option label="Tron / TRC20" value="tron"></el-option>
          <el-option label="Binance Smart Chain / BEP20" value="bsc"></el-option>
          <el-option label="Ethereum / ERC20" value="eth"></el-option>
          <el-option label="Polygon / ERC20" value="pol"></el-option>
          <el-option v-if="allowAdjust" :label="$t('message.binanceOnlineOrder')" value="binance_online"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('message.walletAddress')" >
        <!-- 手动输入钱包地址 -->
        <el-input v-model="form.walletAddress" :placeholder="$t('message.enterWalletAddress')"></el-input>

        <!-- 隐藏的文件输入 -->
    <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />

        <!-- 或者使用 qrcode-capture 用于文件上传 -->
        <qrcode-capture @detect="onDecode" ref="qrcodeCapture" style="display: none;">
        </qrcode-capture>

        <!-- 自定义按钮 -->
        <el-button type="success" @click="triggerFileInput" style="margin-top: 10px;">{{
          t("message.uploadImage")
          }}</el-button>

        <!-- 显示用户上传的二维码图片 -->
        <div v-if="qrCodeImage">
          <img :src="qrCodeImage" alt="Uploaded QR Code" style="max-width: 100%" />
        </div>
      </el-form-item>

    </el-form>

    <el-button type="primary" @click="submitForm" style="width: 100%; margin-left: 0;">{{
          $t("message.submit")
          }}</el-button>

    <!-- 人民币金额弹窗 -->
    <el-dialog v-model="rmbDialogVisible" title="输入人民币金额">
      <el-form label-width="100px">
        <el-form-item label="货币类型">
          <el-radio-group v-model="currencyType" @change="adjustRate">
            <el-radio label="HKD">港币</el-radio>
            <el-radio label="RMB">人民币</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="人民币金额">
          <el-input v-model="rmbInput" type="number" placeholder="请输入人民币金额"></el-input>
        </el-form-item>
        <el-form-item label="港币金额">
          <div>{{ calculatedHKD }} HKD</div>
        </el-form-item>
        <el-form-item label="汇率">
          <el-input v-model="rmbToHkdRate" type="number" placeholder="请输入汇率"></el-input>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button @click="rmbDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmRmbAmount">确定</el-button>
      </template>
    </el-dialog>

  </div>

  <!-- 提交后的显示部分 -->
  <div v-else class="submission-result">
    <div class="receipt">
      <h2>{{ t("message.receiptTitle") }}</h2>
      <p>
        <strong>{{ t("message.orderNumber") }}:</strong> {{ orderNumber }}
      </p>
      <p>
        <strong>{{ t("message.orderTime") }}:</strong>
        {{ formatDateToCustom(convertUTCToLocalTime(orderCreated_at)) }}
      </p>

      <p style="font-size: 24px">
        <strong>{{ t("message.amount") }}:</strong>
        {{ formatNumberWithCommasNoDecimals(form.amount) }} HKD
      </p>
      <p>
        <strong>{{ t("message.usdtAmount") }}:</strong>
        {{ formatNumberWithCommas(form.usdtAmount) }} USDT
      </p>
      <p>
        <strong>{{ t("message.exchangeRate") }} {{ form.exchangeRate }} HKD/USDT</strong>
      </p>
      <p>
        <strong>{{ t("message.walletAddress") }}:</strong>
        {{ form.walletAddress }}
      </p>

      <!-- QR Code for Wallet Address -->
      <qrcode-vue :value="form.walletAddress" :size="150"></qrcode-vue>

      <p>
        <strong>{{ t("message.receiptDeclaration") }}:</strong>
      </p>
      <p>{{ t("message.receiptDeclarationContent") }}</p>

      <p>
        <strong>{{ t("message.printTime") }}:</strong>
        {{ formatDateToCustom(new Date()) }}
      </p>

      <!-- Print button -->
      <el-button type="primary" @click="printReceipt">{{
        t("message.printReceipt")
        }}</el-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { validateWalletAddress } from "@/utils/validateWalletAddress";
import { API_BASE_URL, PRICE_API_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import QrcodeVue from "vue-qrcode";
import { useRouter } from "vue-router";
import { QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeVue,
    QrcodeCapture
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();
    const qrCodeImage = ref("");
    const orderCreated_at = ref();
    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 100);
    const minAmountForNoFee = ref(process.env.VUE_APP_MIN_AMOUNT_FOR_NO_FEE || 20000);
    const hkdFeeAmount = ref(process.env.VUE_APP_HKD_FEE || 30);

    const form = ref({
      amount: "",
      usdtAmount: "",
      walletAddress: "",
      chain: "tron",
      exchangeRate: "0",
      hkdFee: "0",
    });
    const exchangeRate = ref(null);
    const largeExchangeRate = ref(0);
    const largeOrderThreshold = ref(0);
    const isSubmitted = ref(false);
    const orderNumber = ref("");
    const isScanning = ref(false);
    const video = ref(null);
    const qrcodeCapture = ref(null)
    const isUsdtInputActive = ref(false);

    const usdtInput = ref("");
    const hkdInput = ref("");

    const rmbDialogVisible = ref(false);
    const rmbInput = ref("");
    const rmbToHkdRate = ref(0);
    const calculatedHKD = ref(0);
    const currencyType = ref('HKD'); // 默认为港币

    // 监听 form.amount 的变化
    watch(hkdInput, (newAmount) => {
      const amount = parseFloat(newAmount);
      
      if (!isNaN(amount)) {
        console.log('hkdInput:', amount);

        if (amount >= largeOrderThreshold.value) {
          form.value.exchangeRate = largeExchangeRate.value;
          // console.log('exchangeRate1:', form.value.exchangeRate);
        } else {
          form.value.exchangeRate = parseFloat(exchangeRate.value); // 使用普通汇率
          // console.log('exchangeRate2:', form.value.exchangeRate);
        }
        form.value.amount = amount.toFixed(0);
        usdtInput.value = "";
        calculateUSDT();
        calculateFee();
      }
      
    });

    function adjustRate() {
      if (currencyType.value === 'RMB') {
        rmbToHkdRate.value = parseFloat((rmbToHkdRate.value * 0.99).toFixed(6)); // 如果选择人民币，汇率乘以0.99，保留6位小数
      } else {
        rmbToHkdRate.value = parseFloat((rmbToHkdRate.value / 0.99).toFixed(6)); // 如果选择港币，恢复原汇率，保留6位小数
      }
    }

    watch([rmbInput, rmbToHkdRate], () => {
      calculatedHKD.value = Math.round(rmbInput.value * rmbToHkdRate.value);
    });

    function confirmRmbAmount() {
      // 确认人民币金额的逻辑
      console.log('Confirmed RMB Amount:', rmbInput.value);
      rmbDialogVisible.value = false;
    }

    const openRmbDialog = () => {
      rmbDialogVisible.value = true;
    };

    onMounted(async () => {
      try {
        // 使用 Alpha Vantage API 获取更精准的汇率
        const apiKey = '5J49RAEYT2VOR2EY'; // 请替换为您的 Alpha Vantage API 密钥
        const response = await fetch(`https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=CNY&to_currency=HKD&apikey=${apiKey}`);
        const data = await response.json();
        
        if (data['Realtime Currency Exchange Rate']) {
          rmbToHkdRate.value = parseFloat(data['Realtime Currency Exchange Rate']['5. Exchange Rate']);
        } else {
          throw new Error('无法获取汇率数据');
        }
      } catch (error) {
        console.error('获取汇率失败:', error);
        ElMessage.error('无法获取汇率，请手动输入');
        
        // 使用备用 API
        try {
          const backupResponse = await fetch('https://api.exchangerate-api.com/v4/latest/CNY');
          const backupData = await backupResponse.json();
          rmbToHkdRate.value = backupData.rates.HKD;
        } catch (backupError) {
          console.error('备用 API 获取汇率失败:', backupError);
          ElMessage.error('无法获取汇率，请手动输入');
        }
      }
    });

    const calculateUSDT = () => {
      if (form.value.exchangeRate && form.value.amount) {
        let hkdAmount = parseFloat(form.value.amount);
        
        // 如果金额小于最低免手续费金额，则减去手续费
        if (hkdAmount < minAmountForNoFee.value) {
          hkdAmount -= parseFloat(hkdFeeAmount.value);
        }
        
        // 确保金额不会变成负数
        hkdAmount = Math.max(hkdAmount, 0);
        
        let usdtAmount = hkdAmount / parseFloat(form.value.exchangeRate);
        form.value.usdtAmount = usdtAmount.toFixed(4);
      }
    };

    const calculateFee = () => {
      if (parseFloat(form.value.amount) < minAmountForNoFee.value) {
        form.value.hkdFee = hkdFeeAmount.value.toString();
      } else {
        form.value.hkdFee = "0";
      }
    };


    watch(usdtInput, (newValue) => {
      
      // 当 usdtInput 变化时，重新计算港币值
      if (newValue && form.value.exchangeRate) {
        let hkdAmount = parseFloat(newValue) * parseFloat(form.value.exchangeRate);
        console.log("hkdAmount:", hkdAmount);
        
        // 判断是否超过大额阈值
        if (hkdAmount >= largeOrderThreshold.value) {
          // 使用大额汇率
          form.value.exchangeRate = largeExchangeRate.value;
          hkdAmount = parseFloat(newValue) * parseFloat(largeExchangeRate.value);
        } else {
          // 使用普通汇率
          form.value.exchangeRate = exchangeRate.value;
        }
        
        form.value.amount = hkdAmount.toFixed(0);
        form.value.usdtAmount = newValue;
        hkdInput.value = "";
        calculateFee();
      } 
      

    });


    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(PRICE_API_URL);
        const data = response.data;
        exchangeRate.value = data.buyPrice;
        largeExchangeRate.value = data.buyPriceLarge;
        largeOrderThreshold.value = data.largeOrderThreshold;
        form.value.exchangeRate = exchangeRate.value;
      } catch (error) {
        console.error("获取汇率失败:", error);
        ElMessage.error(t("message.exchangeRateError"));
      }
    };

    const validateForm = () => {
      console.log('form:', form.value);
      if (form.value.chain === 'binance_online') {
        console.log('binance_online - 不验证地址');
        return true;
      }
      
      // 如果 allowAdjust 为假，则检查金额是否为100的倍数
      if (!props.allowAdjust) {
        if (parseFloat(form.value.amount) % 100 !== 0) {
          ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
          return false;
        }
      }

      // 检查金额是否大于最小金额
      if (parseFloat(form.value.amount) < minAmount.value) {
        ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
        return false;
      }

      // 验证港币金额是否等于USDT金额 * 汇率，允许误差小于0.001
      const calculatedHKD = parseFloat(form.value.usdtAmount) * parseFloat(exchangeRate.value) + parseFloat(form.value.fee);
      if (Math.abs(calculatedHKD - parseFloat(form.value.amount)) > 0.001) {
        ElMessage.error(t("message.amountMismatchError"));
        return false;
      }

      // 只有在非币安线上订单时才验证钱包地址
      if (form.value.chain !== 'binance_online') {
        console.log('form.value.walletAddress:', form.value.walletAddress);
        console.log('form.value.chain:', form.value.chain);
        // 验证钱包址格式是否正确
        const addressValidation = validateWalletAddress(
          form.value.walletAddress,
          form.value.chain
        );
        if (!addressValidation.valid) {
          ElMessage.error(t(`message.${addressValidation.error}`));
          return false;
        }
      }

      return true;
    };

    // 增加汇率调整功能
    const increaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) + 0.001).toFixed(4); // 每次增加 0.001
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) - 0.001).toFixed(4); // 每次减少 0.001
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm()) return;

      orderNumber.value = generateOrderId();

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", form.value.usdtAmount);
      formData.append("exchange_rate", form.value.exchangeRate);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "buy");
      formData.append("fee_hkd", form.value.hkdFee);

      // 附加文件
      const fileInput = document.querySelector('input[type="file"]');
      const file = fileInput.files[0];
      if (file) {
        formData.append("qr_code_image", file);
      }

      try {
        const response = await axios.post(
          `${API_BASE_URL}/preorder`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // // 提交成功后，接收后端返回的订单数据
        const orderData = response.data.order;

        orderNumber.value = orderData.order_number;
        form.value.amount = orderData.amount;
        form.value.usdtAmount = orderData.usdt_amount;
        exchangeRate.value = orderData.exchange_rate;
        form.value.walletAddress = orderData.receive_address;
        form.value.chain = orderData.chain_type;
        orderCreated_at.value = orderData.created_at;

        // 显示成功消息
        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("订单提交失败:", error);
        ElMessage.error(t("message.submitFailed"));
      }
    };

    const goToHome = () => {
      // 重置表单
      form.value = {
        amount: "",
        walletAddress: "",
        chain: "tron",
      };
      usdtInput.value = "";
      hkdInput.value = "";
      isSubmitted.value = false;
    };

    // 取整港币金额为 100 的倍数
    const adjustHKD = () => {
      if (form.value.amount) {
        // 調整港幣金額為100的倍數
        const adjustedAmount = Math.floor(parseFloat(form.value.amount) / 100) * 100;
        form.value.amount = adjustedAmount;
        
        // 更新 hkdInput
        hkdInput.value = adjustedAmount;
        
        // 根據調整後的港幣金額計算 USDT 金額
        // if (form.value.exchangeRate) {
        //   const amountAfterFee = adjustedAmount - parseFloat(form.value.hkdFee);
        //   const usdtAmount = amountAfterFee / parseFloat(form.value.exchangeRate);
        //   form.value.usdtAmount = usdtAmount.toFixed(4);
          
        //   // 更新 usdtInput
        //   // usdtInput.value = usdtAmount.toFixed(4);

        //   // 打印相關值以觀察錯誤
        //   console.log('調整後的港幣金額:', adjustedAmount);
        //   console.log('手續費:', form.value.hkdFee);
        //   console.log('扣除手續費後的金額:', amountAfterFee);
        //   console.log('匯率:', form.value.exchangeRate);
        //   console.log('計算得出的USDT金額:', usdtAmount);
        //   console.log('四捨五入後的USDT金額:', usdtAmount.toFixed(4));
        // }
      }
    };

    const viewReceipt = () => {
      router.push({
        name: "ReceiptBuy",
        params: {
          orderNumber: orderNumber.value,
        },
      });
    };

    const fileInput = ref(null);

    // 触发 file input 的点击事件
    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const onFileChange = async (event) => {
      const file = event.target.files[0]
      if (file) {
        // 显示选择的图
        const reader = new FileReader()
        reader.onload = (e) => {
          qrCodeImage.value = e.target.result
        }
        reader.readAsDataURL(file)

        // 将文件传递给 qrcode-capture 组件
        await nextTick()
        if (qrcodeCapture.value && qrcodeCapture.value.$el) {
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(file)
          qrcodeCapture.value.$el.files = dataTransfer.files
          qrcodeCapture.value.$el.dispatchEvent(new Event('change', { bubbles: true }))
        }
      }
    }

    const triggerQrcodeCapture = () => {
      if (qrcodeCapture.value) {
        const inputElement = qrcodeCapture.value.$el
        if (inputElement && inputElement.tagName === 'INPUT') {
          inputElement.click()
        }
      }
    }

    const printReceipt = () => {
      router.push({ name: "PrintReceipt", params: { orderNumber: orderNumber.value } });
    };

    function formatDateToCustom(dateString) {
      // 将原始日期字符串转换为 Date 对象
      const date = new Date(dateString);

      // 获取份、月份、期、小时、分钟和秒
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // 拼接成所需的格式 "喻喆 YYYY-MM-DD HH:mm:ss"
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function convertUTCToLocalTime(utcTimeString) {
      // 将 UTC 时间字符串转换为 Date 对象
      const utcDate = new Date(utcTimeString);

      // 使用 toLocaleString 将时间转换为本地时区，并自定义格式
      return utcDate.toLocaleString("zh-CN", {
        timeZone: "Asia/Hong_Kong", // 设定特定的时区
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }

    const onDecode = (content) => {
      const decodedContent = content[0].rawValue;
      console.log('解码的二维码内容:', decodedContent);
      
      // 检查是否符合特定格式
      const match = decodedContent.match(/^(ethereum:)?(0x[0-9a-fA-F]{40})/);
      console.log('match:', match);
      if (match) {
        // 提取地址
        form.value.walletAddress = match[2];
        // 设置对应的链为 eth
        form.value.chain = 'eth';
        console.log('检测到以太坊地址:', form.value.walletAddress);
        console.log('设置链为:', form.value.chain);
      } else {
        // 如果不符合特定格式，则使用原始内容
        form.value.walletAddress = decodedContent;
        console.log('使用原始地址:', form.value.walletAddress);
      }
    };

    // 新增方法来处理 USDT 输入框的焦点事件
    const handleUsdtFocus = () => {
      isUsdtInputActive.value = true;
    };

    const handleUsdtBlur = () => {
      isUsdtInputActive.value = false;
      // calculateHKD();
    };

    onMounted(async () => {
      console.log('props:', props.allowAdjust);
      await fetchExchangeRate();
    });

    return {
      form,
      exchangeRate,
      isSubmitted,
      orderNumber,
      submitForm,
      t,
      formatNumberWithCommas,
      goToHome,
      formatNumberWithCommasNoDecimals,
      adjustHKD,
      viewReceipt,
      minAmount,
      qrCodeImage,
      triggerQrcodeCapture,
      isScanning,
      video,
      printReceipt,
      orderCreated_at,
      formatDateToCustom,
      convertUTCToLocalTime,
      increaseExchangeRate,
      decreaseExchangeRate,
      onDecode,
      qrcodeCapture,
      triggerFileInput,
      onFileChange,
      fileInput,
      minAmountForNoFee,
      hkdFeeAmount,
      handleUsdtFocus,
      handleUsdtBlur,
      usdtInput,
      hkdInput,
      rmbDialogVisible,
      rmbInput,
      calculatedHKD,
      openRmbDialog,
      confirmRmbAmount,
      rmbToHkdRate,
      currencyType,
      adjustRate,
      largeExchangeRate,
      largeOrderThreshold,
    };
  },
};
</script>

<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.buy-page {
  margin: 20px;
}
.buy-page .el-button {
  margin-left: 0px;
}

@media print {
  body {
    width: 57mm;
    margin: 0;
    padding: 0;
  }

  .receipt {
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .no-print,
  button,
  .el-button {
    display: none !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.fee-message {
  color: #E6A23C;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
}
</style>