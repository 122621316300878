<template>
  <div v-if="!isSubmitted" class="sell-page">
    <div style="text-align: center; margin-bottom: 20px">
      <h1 style="font-size: 28px; font-weight: bold; color: #333">
        {{ $t("message.sellUSDT") }}
      </h1>
      <p style="font-size: 18px; color: #666">{{ $t("message.USDTToHKD") }}</p>
    </div>

    <!-- 表單部分 -->
    <el-form :model="form" label-width="100px">
      <el-card class="box-card" style="margin-bottom: 20px">
        <template #header>
          <div class="card-header" style="text-align: left">
            <span>{{ $t("message.amountInput") }}</span>
          </div>
        </template>

        <el-form-item :label="$t('message.usdtAmount')">
          <el-input
            v-model="usdtInput"
            :placeholder="$t('message.enterUSDT')"
          >
            <template #append>
              <div style="width: 50px; text-align: center">USDT</div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('message.amount')">
          <el-input
            v-model="hkdInput"
            :placeholder="$t('message.amount')"
          >
            <template #append>
              <div style="width: 50px; text-align: center">HKD</div>
            </template>
          </el-input>
          
          <div v-if="parseFloat(form.amount) < minAmount || parseFloat(form.amount) % 100 !== 0" class="error-message">
            {{ $t("message.amountHint", { minAmount: minAmount }) }}
          </div>

          <el-button type="success" @click="adjustHKD" :style="{
              display: 'block', 
              marginTop: (parseFloat(form.amount) % 100 !== 0 || parseFloat(form.amount) < minAmount) ? '0' : '10px'
            }">
            {{ t("message.adjustAmount") }}
          </el-button>
        </el-form-item>
      </el-card>

      <el-form-item :label="t('message.usdtAmount')">
        <div class="usdt-amount">
          {{ formatNumberWithCommas(form.usdtAmount) }}
          <img
            src="@/assets/usdt_logo.svg"
            alt="USDT"
            style="width: 30px; height: 30px; margin-left: 5px"
          />
        </div>
      </el-form-item>

      <el-form-item :label="$t('message.exchangeRate')">
        <div style="display: flex; align-items: center">
          <el-button v-if="allowAdjust" @click="decreaseExchangeRate"
            >-</el-button
          >
          <el-input
            v-model="form.exchangeRate"
            disabled
            style="width: 100px; text-align: center"
          ></el-input>
          <el-button v-if="allowAdjust" @click="increaseExchangeRate"
            >+</el-button
          >
        </div>
      </el-form-item>

      <el-form-item :label="t('message.amount')">
        <div class="usdt-amount">
          {{ formatNumberWithCommasNoDecimals(form.amount) }}
          <img
            src="@/assets/hkd.png"
            alt="HKD"
            style="width: 30px; height: 30px; margin-left: 5px"
          />
        </div>
      </el-form-item>

      <el-form-item :label="$t('message.chain')">
        <el-select
          v-model="form.chain"
          @change="handleChainChange"
          :placeholder="$t('message.selectChain')"
        >
          <el-option
            v-for="option in chainOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('message.walletAddress')">
        <el-input v-model="form.walletAddress" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submitForm" style="width: 100%">{{
      $t("message.submit")
    }}</el-button>
  </div>

  <!-- 提交后的顯示部分 -->
  <div v-else class="submission-result">
    <p>{{ t("message.orderNumber") }}: {{ orderNumber }}</p>
    <p>
      {{ t("message.amount") }}:
      {{ formatNumberWithCommasNoDecimals(form.amount) }} HKD
    </p>
    <p style="font-size: 36px">
      {{ t("message.usdtAmount") }}:
      {{ formatNumberWithCommas(form.usdtAmount) }} USDT
    </p>
    <p>{{ exchangeRate }} HKD/USDT</p>
    <p>{{ t("message.walletAddress") }}: {{ form.walletAddress }}</p>
    <!-- 新增二維碼顯示 -->
    <qrcode-vue :value="form.walletAddress" :size="200"></qrcode-vue>

    <!-- 增加查看收據按鈕 -->
    <el-button type="primary" @click="viewReceipt">{{
      t("message.viewReceipt")
    }}</el-button>

    <el-button type="primary" @click="goToHome">{{
      t("message.returnToHome")
    }}</el-button>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import QrcodeVue from "vue-qrcode";

import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { API_BASE_URL, PRICE_API_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import { useRouter } from "vue-router"; // 導入 useRouter

export default {
  components: {
    QrcodeVue,
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter(); // 使用 useRouter
    const { t } = useI18n();
    
    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 1000);

    const form = ref({
      amount: "",
      usdtAmount: "",
      walletAddress: "",
      chain: "tron",
      hkdFee: "0",
    });

    const usdtInput = ref("");
    const hkdInput = ref("");

    const exchangeRate = ref(null);
    const largeExchangeRate = ref(0); // 新增：大额卖出价
    const largeOrderThreshold = ref(0); // 新增：大额订单阈值
    const isSubmitted = ref(false);
    const orderNumber = ref("");

    // const isUsdtInputActive = ref(false);
    // const isHkdInputActive = ref(false);

    const chainOptions = ref([]);

    const calculateHKD = () => {
      if (exchangeRate.value && usdtInput.value) {
        let hkdAmount = parseFloat(usdtInput.value) * parseFloat(exchangeRate.value);
        
        // 將金額調整為100的倍數
        hkdAmount = Math.ceil(hkdAmount / 100) * 100;

        form.value.amount = hkdAmount.toFixed(0);
      } else {
        form.value.amount = "";
      }
    };

    // const calculateActualHKD = () => {
    //   if (exchangeRate.value && usdtInput.value) {
    //     let hkdAmount = parseFloat(usdtInput.value) * parseFloat(exchangeRate.value);
        
    //     // 不調整為100的倍數，保留兩位小數
    //     form.value.amount = hkdAmount.toFixed(0);
    //   } else {
    //     form.value.amount = "";
    //   }
    // };

    // watch([usdtInput, hkdInput], () => {
    //   calculateActualHKD();
    //   calculateUSDT();
    // });

    watch(usdtInput, (newValue) => {
      // form.value.usdtAmount = newValue;
      // 当 usdtInput 变化时，重新计算港币值
      if (newValue && form.value.exchangeRate) {
        let hkdAmount = parseFloat(newValue) * parseFloat(form.value.exchangeRate);
        console.log("hkdAmount:", hkdAmount);
        
        // 判断是否超过大额阈值
        if (hkdAmount >= largeOrderThreshold.value) {
          // 使用大额汇率
          form.value.exchangeRate = largeExchangeRate.value;
          hkdAmount = parseFloat(newValue) * parseFloat(largeExchangeRate.value);
        } else {
          // 使用普通汇率
          form.value.exchangeRate = exchangeRate.value;
        }
        
        hkdInput.value = "";
        form.value.amount = hkdAmount.toFixed(0);
        form.value.usdtAmount = newValue;
      }
    });

    watch(hkdInput, (newValue) => {
      const amount = parseFloat(newValue);
      if (!isNaN(amount)) {
        if (amount >= largeOrderThreshold.value) {
          form.value.exchangeRate = largeExchangeRate.value;
        } else {
          form.value.exchangeRate = parseFloat(exchangeRate.value);
        }
        form.value.amount = amount.toFixed(0);
        usdtInput.value = "";
        calculateUSDT();
      }
    });

    const calculateUSDT = () => {
      if (form.value.exchangeRate && form.value.amount) {
        let usdtAmount = parseFloat(form.value.amount) / parseFloat(form.value.exchangeRate);
        form.value.usdtAmount = usdtAmount.toFixed(4);
      } else {
        form.value.usdtAmount = "";
      }
    };


    const adjustHKD = () => {
      if (form.value.amount) {
        // 將港幣金額向下取整為100的倍數
        const adjustedHKD = Math.floor(parseFloat(form.value.amount) / 100) * 100;
        form.value.amount = adjustedHKD.toString();

        // 根據調整後的港幣金額計算USDT金額
        if (form.value.exchangeRate) {
          const usdtAmount = adjustedHKD / parseFloat(form.value.exchangeRate);
          form.value.usdtAmount = usdtAmount.toFixed(4);
          usdtInput.value = usdtAmount.toFixed(4);
        }
      }
    };


    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(PRICE_API_URL);
        const data = response.data;
        exchangeRate.value = data.sellPrice;
        largeExchangeRate.value = data.sellPriceLarge; // 新增：获取大额卖出价
        largeOrderThreshold.value = data.largeOrderThreshold; // 新增：获取大额订单阈值
        form.value.exchangeRate = exchangeRate.value;
        console.log("當前買入價格:", data.buyPrice);
        console.log("當前賣出價格:", data.sellPrice);
        console.log("更新時間:", data.updateTime);
        console.log("其他信息:", data.otherInfo);
      } catch (error) {
        console.error("獲取匯率失敗:", error);
        ElMessage.error(t("message.exchangeRateError"));
      }
    };

    const fetchAssetsAddresses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/assets_addresses`);
        console.log('Assets Addresses:', response.data);
        chainOptions.value = response.data.data.map(item => ({
          label: `${item.chain_full_name} / ${item.type}`,
          value: item.chain_full_name,
          address: item.address
        }));

        if (chainOptions.value.length > 0) {
          form.value.chain = chainOptions.value[0].value;
          handleChainChange(form.value.chain);
        }
      } catch (error) {
        console.error("Error fetching assets addresses:", error);
        ElMessage.error(t("message.fetchAddressesError"));
      }
    };

    const handleChainChange = (value) => {
      const selectedChain = chainOptions.value.find(option => option.value === value);
      if (selectedChain) {
        form.value.chain = selectedChain.value;
        form.value.walletAddress = selectedChain.address;
      }
    };

    const validateForm = () => {
      const amount = parseFloat(form.value.amount);
      
      if (isNaN(amount) || amount % 100 !== 0 || amount < minAmount.value) {
        ElMessage.error(t("message.amountError", { minAmount: minAmount.value }));
        return false;
      }

      // 驗證港幣金額是否等於USDT金額 * 匯率，允許誤差小於0.001
      const calculatedHKD = parseFloat(form.value.usdtAmount) * parseFloat(form.value.exchangeRate);
      if (Math.abs(calculatedHKD - amount) > 0.001) {
        ElMessage.error(t("message.amountMismatchError"));
        return false;
      }

      return true;
    };

    const increaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) + 0.001).toFixed(4); // 每次增加 0.001
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      form.value.exchangeRate = (parseFloat(form.value.exchangeRate) - 0.001).toFixed(4); // 每次減少 0.001
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm()) return;

      orderNumber.value = generateOrderId();

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", form.value.usdtAmount);
      formData.append("exchange_rate", form.value.exchangeRate);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "sell");

      console.log("formData:", Object.fromEntries(formData));

      try {
        await axios.post(`${API_BASE_URL}/preorder`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("訂單提交失敗:", error);
        ElMessage.error(t("message.submitFailed"));
      }
    };

    const resetForm = () => {
      form.value = {
        amount: "",
        usdtAmount: "",
        walletAddress: "",
        chain: "tron",
      };
      isSubmitted.value = false;
    };

    onMounted(async () => {
      console.log("props:", props.allowAdjust);
      await fetchExchangeRate();
      await fetchAssetsAddresses();
    });

    const viewReceipt = () => {
      router.push({
        name: "ReceiptSell",
        params: {
          orderNumber: orderNumber.value, // 將訂單號傳遞到收據頁面
        },
      });
    };

    return {
      form,
      usdtInput,
      hkdInput,
      exchangeRate,
      isSubmitted,
      orderNumber,
      calculateUSDT,
      calculateHKD,
      adjustHKD,
      submitForm,
      handleChainChange,
      resetForm,
      t,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals,
      viewReceipt,
      minAmount,
      increaseExchangeRate,
      decreaseExchangeRate,
      chainOptions,
      largeExchangeRate,
      largeOrderThreshold,
    };
  },
};
</script>

<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
  margin-bottom: 10px;
}

.el-button {
  margin-left: 10px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.sell-page {
  margin: 20px;
}

.sell-page .el-button {
  margin-left: 0px;
}
</style>