<template>
  <div class="pending-orders">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="18" class="content-col">
        <h1>{{ t("message.pendingOrders") }}</h1>

        <!-- 查询条件 -->
        <el-form :inline="true" class="rice-form-inline">
          <!-- 訂單號輸入框 -->
          <el-form-item :label="t('message.orderNumber')">
            <el-input v-model="queryParams.order_number" placeholder="請輸入訂單號"></el-input>
          </el-form-item>
          <!-- 接收地址输入框 -->
          <el-form-item :label="t('message.receiveAddress')">
            <el-input v-model="queryParams.receive_address" placeholder="請輸入接收地址"></el-input>
          </el-form-item>

          <!-- 發送地址输入框 -->
          <el-form-item :label="t('message.sendAddress')">
            <el-input v-model="queryParams.send_address" placeholder="請輸入發送地址"></el-input>
          </el-form-item>
          <!-- 状态选择器 -->
          <el-form-item :label="t('message.status')">
            <el-select v-model="queryParams.status" :placeholder="t('message.selectStatus')" @change="handleStatusChange" value="">
              <el-option :label="t('message.all')" value="all"></el-option>
              <el-option :label="t('message.statusPending')" value="pending"></el-option>
              <el-option :label="t('message.statusCompleted')" value="completed"></el-option>
              <el-option :label="t('message.statusClosed')" value="closed"></el-option>
            </el-select>
          </el-form-item>

          <!-- 开始时间选择器 -->
          <el-form-item :label="t('message.startTime')">
            <el-date-picker v-model="queryParams.start_time" type="date" :placeholder="t('message.startDate')"
              :disabled-date="disableFutureDates" :shortcuts="shortcuts" />
          </el-form-item>

          <!-- 结束时间选择器 -->
          <el-form-item :label="t('message.endTime')">
            <el-date-picker v-model="queryParams.end_time" type="date" :placeholder="t('message.endDate')" :shortcuts="shortcuts"
              :disabled-date="disableFutureDates"></el-date-picker>
          </el-form-item>

          <!-- 搜索按钮 -->
          <el-form-item>
            <el-button type="primary" @click="fetchPendingOrders">{{ t("message.search") }}</el-button>
          </el-form-item>
        </el-form>

        
        <!-- 订单列表 -->
        <el-table :data="orders" v-if="orders.length > 0" border>
          <el-table-column label="Order Details">
            <template #default="scope">
              <div class="order-details">
                <!-- 第一行 -->
                <div class="order-row">
                  <div class="order-col">
                    <strong>{{ t("message.orderNumber") }}:</strong> {{ scope.row.order_number }}
                  </div>
                  <div class="order-col">
                    <strong>{{ t("message.status") }}:</strong> 
                    {{
                      scope.row.status === "pending"
                        ? t("message.statusPending")
                        : scope.row.status === "completed"
                          ? t("message.statusCompleted")
                          : t("message.statusClosed")
                    }}
                  </div>
                </div>
                <!-- 第二行 -->
                <div class="order-row">
                  <div class="order-col">
                    <qrcode-vue :value="scope.row.receive_address" :size="100"></qrcode-vue>
                  </div>
                  <div class="order-col">
                    
                    <div><strong>{{ t("message.orderType") }}:</strong> {{ scope.row.order_type === 'buy' ? t("message.buyOrder") : t("message.sellOrder") }}</div>
                    <div v-if="scope.row.order_type === 'sell'">
                      <div>{{ t("message.usdtAmount") }}: {{ formatNumberWithCommas(scope.row.usdt_amount) }}</div>
                      <div>{{ t("message.exchangeRate") }}: {{ scope.row.exchange_rate }}</div>
                      <div>{{ t("message.amount") }} (HKD): {{ formatNumberWithCommasNoDecimals(scope.row.amount) }}</div>
                    </div>
                    <div v-else>
                      <div>{{ t("message.amount") }} (HKD): {{ formatNumberWithCommasNoDecimals(scope.row.amount) }}</div>
                      <div>{{ t("message.exchangeRate") }}: {{ scope.row.exchange_rate }}</div>
                      <div>{{ t("message.usdtAmount") }}: {{ formatNumberWithCommas(scope.row.usdt_amount) }}</div>
                    </div>
                    <div>{{ t("message.fee") }}: {{ formatNumberWithCommas(scope.row.fee_usdt) }}</div>
                    <div>{{ t("message.transactionHash") }}: {{ scope.row.transaction_hash }}</div>
                    <div>{{ t("message.remark") }}: {{ scope.row.remark }}</div>
                  </div>
                </div>
                <!-- 第三行 -->
                <div class="order-row">
                  <div class="order-col">
                    <strong>{{ t("message.receiveAddress") }}:</strong> {{ scope.row.receive_address }}
                  </div>
                  <div class="order-col">
                    <strong>{{ t("message.createdAt") }}:</strong> {{ formatDateToView(scope.row.created_at) }}
                  </div>
                </div>
                <!-- 第四行 -->
                <div class="order-row">
                  <div class="order-col">
                    <el-button v-if="scope.row.status === 'pending'" type="success"
                      @click="markAsCompleted(scope.row.order_number)">
                      {{ t("message.markCompleted") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="warning" @click="closeOrder(scope.row.order_number)">
                      {{ t("message.closeOrder") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary" @click="handleEdit(scope.row.order_number)">
                      {{ t("message.edit") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'closed'" type="primary" @click="resetOrder(scope.row.order_number)">
                      {{ t("message.resetOrder") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary"
                      @click="printReceipt(scope.row.order_number)">{{
                        t("message.printReceipt")
                      }}</el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary"
                      @click="printMoneyReceipt(scope.row.order_number, scope.row.order_type)">
                      {{ t("message.printReceipt") + 'A4' }}
                    </el-button>
                    <el-button v-if="!scope.row.is_account_transferred" type="primary" @click="showAccountTransferDialog(scope.row)">
                      {{ t("message.accountTransfer") }}
                    </el-button>

                      
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页控件 -->
        <el-pagination v-if="total > 0" background :current-page="queryParams.page" :page-size="queryParams.limit"
          :total="total" @current-change="handlePageChange"></el-pagination>

        <div v-else>
          <p>{{ t("message.noPendingOrders") }}</p>
        </div>

        <!-- 编辑弹窗 -->
        <el-dialog v-model="isEditDialogVisible" :title="t('message.editOrder')">
          <el-form :model="editForm" label-width="150px">
            <el-form-item :label="t('message.orderNumber')">
              <el-input v-model="editForm.order_number" disabled></el-input>
            </el-form-item>

            <!-- 金額 -->
            <el-form-item :label="t('message.amount')">
              <el-input v-model.number="editForm.amount" type="number" step="0.01" :placeholder="t('message.enterAmountHKD')">
              </el-input>
            </el-form-item>

            <!-- USDT數量 -->
            <el-form-item :label="t('message.usdtAmount')">
              <el-input v-model.number="editForm.usdt_amount" type="number" step="0.000001" :placeholder="t('message.enterUSDTAmount')">
              </el-input>
            </el-form-item>

            <!-- 匯率 -->
            <el-form-item :label="t('message.exchangeRate')">
              <el-input v-model.number="editForm.exchange_rate" type="number" step="0.001"
                :placeholder="t('message.enterRateValue')">
              </el-input>
            </el-form-item>

            <!-- 訂單類型 -->
            <el-form-item :label="t('message.orderType')">
              <el-select v-model="editForm.order_type">
                <el-option :label="t('message.buy')" value="buy"></el-option>
                <el-option :label="t('message.sell')" value="sell"></el-option>
              </el-select>
            </el-form-item>

            <!-- 接收地址 -->
            <el-form-item :label="t('message.receiveAddress')">
              <el-select v-if="editForm.order_type === 'sell'" v-model="editForm.receive_address" :placeholder="t('message.selectReceiveAddress')" @change="updateChainAndAddressType">
                <el-option
                  v-for="address in assetsAddresses"
                  :key="address.id"
                  :label="`${address.name} (${address.chain_full_name}) - ${address.address}`"
                  :value="address.address"
                ></el-option>
              </el-select>
              <el-input v-else v-model="editForm.receive_address"></el-input>
            </el-form-item>

            <!-- 地址類型 (Buy) -->
            <el-form-item v-if="editForm.order_type === 'buy'" :label="t('message.receiveAddressType')">
              <el-select v-model="editForm.address_type">
                <el-option :label="t('message.wallet')" value="wallet"></el-option>
                <el-option :label="t('message.exchange')" value="exchange"></el-option>
                <el-option :label="t('message.paymentChannelReceiveAddress')" value="payment_channel"></el-option>
              </el-select>
            </el-form-item>

            <!-- 發送地址 -->
            <el-form-item :label="t('message.sendAddress')">
              <el-select v-if="editForm.order_type === 'buy'" v-model="editForm.send_address" :placeholder="t('message.selectSendAddress')" @change="updateChainAndAddressType">
                <el-option
                  v-for="address in assetsAddresses"
                  :key="address.id"
                  :label="`${address.name} (${address.chain_full_name}) - ${address.address}`"
                  :value="address.address"
                ></el-option>
              </el-select>
              <el-input v-else v-model="editForm.send_address"></el-input>
            </el-form-item>

            <!-- 地址類型 (Sell) -->
            <el-form-item v-if="editForm.order_type === 'sell'" :label="t('message.sendAddressType')">
              <el-select v-model="editForm.address_type">
                <el-option :label="t('message.wallet')" value="wallet"></el-option>
                <el-option :label="t('message.exchange')" value="exchange"></el-option>
              </el-select>
            </el-form-item>

            <!-- 鏈的名稱 -->
            <el-form-item :label="t('message.chainType')">
              <el-select v-model="editForm.chain_type">
                <el-option label="Tron / TRC20" value="tron"></el-option>
                <el-option label="Binance Smart Chain / BEP20" value="bsc"></el-option>
                <el-option label="Ethereum / ERC20" value="eth"></el-option>
                <el-option label="Polygon / POL" value="pol"></el-option>
              </el-select>
            </el-form-item>

            <!-- 交易Hash -->
            <el-form-item :label="t('message.transactionHash')">
              <el-input v-model="editForm.transaction_hash"></el-input>
            </el-form-item>

            <el-form-item :label="t('message.platformGasFeeUSDT')">
              <el-input v-model="editForm.fee_usdt" :placeholder="t('message.enterPlatformOrGasFee')"></el-input>
            </el-form-item>

            <el-form-item :label="t('message.hkdFee')">
              <el-input v-model="editForm.fee_hkd" type="number" :placeholder="t('message.enterHKDFee')"></el-input>
            </el-form-item>

            <el-form-item :label="t('message.remark')">
              <el-input v-model="editForm.remark"></el-input>
            </el-form-item>
          </el-form>

          <template v-slot:footer>
            <div class="dialog-footer">
              <el-button @click="isEditDialogVisible = false">{{ t('message.cancel') }}</el-button>
              <el-button type="primary" @click="submitEdit">{{ t('message.save') }}</el-button>
            </div>
          </template>
        </el-dialog>

        <!-- 账户转账弹窗 -->
        <el-dialog v-model="isAccountTransferDialogVisible" :title="t('message.accountTransfer')">
          <el-form :model="accountTransferForm" label-width="150px">
            <el-form-item :label="t('message.orderNumber')">
              <el-input v-model="accountTransferForm.order_number" disabled></el-input>
            </el-form-item>
            <el-form-item :label="t('message.orderType')">
              <el-input v-model="accountTransferForm.order_type" disabled></el-input>
            </el-form-item>
            <el-form-item :label="t('message.hkdAmount')">
              <el-input v-model="accountTransferForm.hkd_amount" disabled></el-input>
            </el-form-item>
            <el-form-item :label="t('message.usdtAmount')">
              <el-input v-model="accountTransferForm.usdt_amount" disabled></el-input>
            </el-form-item>
            <el-form-item :label="t('message.sourceAccount')">
              <el-select v-model="accountTransferForm.source_account_id" placeholder="请选择来源账户">
                <el-option
                  v-for="account in sourceAccounts"
                  :key="account.value"
                  :label="account.label"
                  :value="account.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="t('message.targetAccount')">
              <el-radio-group v-model="accountTransferForm.target_account_id">
                <el-radio v-for="account in targetAccounts" :key="account.value" :label="account.value">
                  {{ account.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="isAccountTransferDialogVisible = false">{{ t('message.cancel') }}</el-button>
              <el-button type="primary" @click="submitAccountTransfer">{{ t('message.confirm') }}</el-button>
            </span>
          </template>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import QrcodeVue from "vue-qrcode";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue'; // 引入 SidebarMenu 组件
import api from '@/utils/api';

export default {
  components: {
    QrcodeVue,
    SidebarMenu // 注册 SidebarMenu 组件
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const { t } = useI18n();

    const orders = ref([]);
    const total = ref(0);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}T16:00:00Z`;
    }

    const queryParams = reactive({
      status: "all",
      start_time: formatDate(yesterday),
      end_time: formatDate(today),
      page: 1,
      limit: 30,
    });
    const isEditDialogVisible = ref(false);
    const editForm = ref({
      amount: '',
      usdt_amount: '',
      exchange_rate: '',
      // ... other fields ...
    });
    const assetsAddresses = ref([]);
    const isAccountTransferDialogVisible = ref(false);
    const accountTransferForm = ref({
      order_number: '',
      order_type: '',
      hkd_amount: '',
      usdt_amount: '',
      source_account_id: '',
      target_account_id: ''
    });

    const sourceAccounts = ref([]);
    const targetAccounts = ref([]);

    const fetchPendingOrders = async () => {
      try {
        const params = {
          status: queryParams.status,
          start_time: queryParams.start_time,
          end_time: queryParams.end_time,
          page: queryParams.page,
          limit: queryParams.limit,
        };

        console.log('Fetching pending orders with params:', params);

        const response = await api.get('/pending', {
          params,
        });

        console.log('Response data:', response.data);
        if (response.data.orders) {
          orders.value = response.data.orders;
        } else {
          orders.value = [];
        }
        total.value = response.data.total || 0;
      } catch (error) {
        console.error("Error fetching pending orders:", error);
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access, redirecting to login');
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          console.log('Forbidden access, user is not admin');
          ElMessage.error('You do not have permission to view pending orders');
        }
        orders.value = [];
      }
    };

    const fetchAssetsAddresses = async () => {
      try {
        const response = await api.get('/assets_addresses');
        console.log('Assets Addresses:', response.data);
        assetsAddresses.value = response.data.data;
      } catch (error) {
        console.error("Error fetching assets addresses:", error);
      }
    };

    const handlePageChange = (newPage) => {
      queryParams.page = newPage;
      fetchPendingOrders();
    };

    const handleEdit = (orderNumber) => {
      const order = orders.value.find(
        (order) => order.order_number === orderNumber
      );
      editForm.value = { ...order };
      isEditDialogVisible.value = true;
    };

    const submitEdit = async () => {
      try {
        await api.put('/preorder', editForm.value);

        isEditDialogVisible.value = false;
        fetchPendingOrders();
        ElMessage.success(t('message.editSuccess')); // 添加修改成功的提示
      } catch (error) {
        console.error("Error updating order:", error);
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access, redirecting to login');
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          console.log('Forbidden access, user is not admin');
          ElMessage.error(t('message.noPermission'));
        } else {
          ElMessage.error(t('message.editFailed')); // 添加修改失败的提示
        }
      }
    };

    const updateOrderStatus = async (orderNumber, status) => {
      try {
        await api.put('/status', {
          order_number: orderNumber,
          new_status: status
        });
        fetchPendingOrders();
      } catch (error) {
        console.error("Error updating order status:", error);
        if (error.response && error.response.status === 401) {
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          ElMessage.error('You do not have permission to perform this action');
        }
      }
    };

    const markAsCompleted = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to mark this order as completed?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "completed");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    const closeOrder = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to close this order?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "closed");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    const resetOrder = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to reset this order?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "pending");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    const updateChainAndAddressType = (selectedAddress) => {
      const selected = assetsAddresses.value.find(address => address.address === selectedAddress);
      if (selected) {
        editForm.value.chain_type = selected.chain_short_name.toLowerCase();
        // 只有在 order_type 不是 'buy' 的情况下才更新 address_type
        if (editForm.value.order_type !== 'buy') {
          editForm.value.address_type = selected.type;
        }
      }
    };

    onMounted(async () => {
      if (!userStore.isLoggedIn) {
        router.push('/login');
        return;
      }

      fetchPendingOrders();
      fetchAssetsAddresses(); // 调用获取资产地址的 API
      fetchFinancialAccounts();
    });

    const disableFutureDates = (time) => {
      return time.getTime() > Date.now();
    };

    const handleStatusChange = (newValue) => {
      console.log("Status changed", newValue);
      fetchPendingOrders();
    };

    const formatDateToView = (row, column, cellValue) => {
      console.log("Cell Value:", cellValue); // 打印日誌
      console.log("Row:", row); // 打印日誌
      console.log("Column:", column); // 打印日誌
      if (!row) return "";
      const date = new Date(row);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log("Formatted Date:", formattedDate); // 打印日誌
      return formattedDate;
    };

    const printReceipt = (orderNumber) => {
      window.open(router.resolve({ name: "PrintReceipt", params: { orderNumber: orderNumber } }).href, '_blank');
    };

    const printMoneyReceipt = (orderNumber, orderType) => {
      if (orderType === 'buy') {
        window.open(router.resolve({ name: "ReceiptBuy", params: { orderNumber: orderNumber } }).href, '_blank');
      } else if (orderType === 'sell') {
        window.open(router.resolve({ name: "ReceiptSell", params: { orderNumber: orderNumber } }).href, '_blank');
      }
    };

    // 监听汇率、金额和手续费的变化
    watch(
      [
        () => editForm.value.amount,
        () => editForm.value.fee_hkd,
        () => editForm.value.exchange_rate,
      ],
      () => {
        calculateUSDTAmount();
      }
    );

    // 计算新的 USDT 金额
    const calculateUSDTAmount = () => {
      const amount = parseFloat(editForm.value.amount);
      const feeHKD = parseFloat(editForm.value.fee_hkd);
      const exchangeRate = parseFloat(editForm.value.exchange_rate);

      if (!isNaN(amount) && !isNaN(feeHKD) && !isNaN(exchangeRate) && exchangeRate !== 0) {
        const netAmount = amount - feeHKD;
        editForm.value.usdt_amount = (netAmount / exchangeRate).toFixed(4);
      } else {
        editForm.value.usdt_amount = '';
      }
    };

    const showAccountTransferDialog = (order) => {
      accountTransferForm.value = {
        order_number: order.order_number,
        order_type: order.order_type === 'buy' ? t('message.buyOrder') : t('message.sellOrder'),
        hkd_amount: formatNumberWithCommasNoDecimals(order.amount),
        usdt_amount: formatNumberWithCommas(order.usdt_amount),
        source_account_id: sourceAccounts.value.length > 0 ? sourceAccounts.value[0].value : '',
        target_account_id: targetAccounts.value.length > 0 ? targetAccounts.value[0].value : '',
        fee_usdt: formatNumberWithCommas(order.fee_usdt)
      };
      isAccountTransferDialogVisible.value = true;
    };

    const submitAccountTransfer = async () => {
      try {
        const payload = {
          operation_type: accountTransferForm.value.order_type === t('message.buyOrder') ? 'buy' : 'sell',
          source_account_id: accountTransferForm.value.source_account_id,
          source_amount: parseFloat(accountTransferForm.value.hkd_amount.replace(/,/g, '')),
          target_account_id: accountTransferForm.value.target_account_id,
          target_amount: parseFloat(accountTransferForm.value.usdt_amount.replace(/,/g, '')) + parseFloat(accountTransferForm.value.fee_usdt || 0),
          order_number: accountTransferForm.value.order_number
        };

        console.log('提交的數據:', payload);

        await api.post('/shop_fund_operation', payload);
        ElMessage.success(t('message.accountTransferSuccess'));
        isAccountTransferDialogVisible.value = false;
        fetchPendingOrders(); // 刷新订单列表
      } catch (error) {
        console.error("提交账户转账时出错:", error);
        ElMessage.error(t('message.accountTransferFailed'));
      }
    };


    const fetchFinancialAccounts = async () => {
      try {
        const response = await api.get('/financial_accounts', { params: { is_frequently_used: true } });
        console.log('金融賬戶數據:', response.data);
        
        // 处理来源账户（银行卡和现金）
        sourceAccounts.value = response.data.data.filter(account => 
          account.type === 'bank_card' || account.type === 'cash'
        ).sort((a, b) => {
          if (a.type === 'cash' && b.type === 'bank_card') return -1;
          if (a.type === 'bank_card' && b.type === 'cash') return 1;
          return 0;
        }).map(account => ({
          value: account.id,
          label: `${account.name} - ${account.type === 'bank_card' ? account.bank_name : '现金'} (${formatNumberWithCommas(account.balance_hkd)} HKD)`
        }));

        console.log('来源账户:', sourceAccounts.value);

        // 处理目标账户（交易所和钱包）
        targetAccounts.value = response.data.data
          .filter(account => account.type === 'wallet' || account.type === 'exchange')
          .sort((a, b) => {
            if (a.type === 'wallet' && b.type === 'exchange') return -1;
            if (a.type === 'exchange' && b.type === 'wallet') return 1;
            return 0;
          })
          .map(account => ({
            value: account.id,
            label: `${account.name} - ${account.type} (${formatNumberWithCommas(account.balance_usdt_available || 0)} USDT)`
          }));

        console.log('目标账户:', targetAccounts.value);

        // 设置默认选项为第一条数据
        if (sourceAccounts.value.length > 0) {
          accountTransferForm.value.source_account_id = sourceAccounts.value[0].value;
        }
        if (targetAccounts.value.length > 0) {
          accountTransferForm.value.target_account_id = targetAccounts.value[0].value;
        }

      } catch (error) {
        console.error('獲取金融賬戶數據失敗:', error);
        ElMessage.error('無法獲取金融賬戶數據，請稍後再試');
      }
    };


    return {
      t,
      orders,
      queryParams,
      total,
      handleEdit,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals,
      markAsCompleted,
      closeOrder,
      resetOrder,
      handlePageChange,
      isEditDialogVisible,
      editForm,
      submitEdit,
      disableFutureDates,
      fetchPendingOrders,
      handleStatusChange,
      formatDateToView,
      printReceipt,
      printMoneyReceipt,
      assetsAddresses,
      updateChainAndAddressType,
      calculateUSDTAmount,
      isAccountTransferDialogVisible,
      accountTransferForm,
      sourceAccounts,
      targetAccounts,
      showAccountTransferDialog,
      submitAccountTransfer,
    };
  },
};
</script>

<style scoped>
.el-button {
  margin-right: 10px;
}

.rice-form-inline .el-select {
  --el-select-width: 160px;
}

.pending-orders {
  padding: 20px;
}

.content-col {
  margin-left: 20px; /* 調整 margin-left */
  margin-right: 20px; /* 調整 margin-right */
}

.order-details {
  border: 1px solid #ebeef5;
  padding: 10px;
  margin-bottom: 10px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.order-col {
  flex: 1;
  padding: 5px;
}
</style>
